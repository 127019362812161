import { useTranslation } from 'react-i18next';
import { BeinkMode, UserRole } from '../../../types';
import {
  faCircleQuestion,
  faPencil,
  faMagicWandSparkles,
  faTimes,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import SimpleButton from '../../lib/SimpleButton';

import { usePanelContext } from './context/usePanelContext';

import { motion } from 'framer-motion';
import { twJoin } from 'tailwind-merge';
import { useHasRole } from '@/hooks/useHasRole';
import { I18Keys } from '@/types/i18next';

type PanelNavigationButton = {
  mode: BeinkMode;
  icon: IconDefinition;
  label: I18Keys<'panel'>;
  condition?: boolean;
};

const PanelNavigation = function () {
  const { t } = useTranslation('panel');
  const {
    showHelp,
    setShowHelp,
    currentMode,
    setCurrentMode,
    isPanelOpen,
    setIsPanelOpen,
  } = usePanelContext();

  const hasRole = useHasRole();
  const inpaintAllowed = hasRole(UserRole.Inpaint);

  const buttons: PanelNavigationButton[] = [
    {
      mode: BeinkMode.Imagine,
      icon: faPencil,
      label: 'Modes.Imagine',
    } as const,
    {
      mode: BeinkMode.Inpaint,
      icon: faMagicWandSparkles,
      label: 'Modes.Inpaint',
      condition: inpaintAllowed,
    } as const,
  ];
  console.log(isPanelOpen);

  return (
    <motion.div
      layout='position'
      className='relative flex items-center justify-between'>
      <motion.div
        layout='position'
        className={twJoin(
          'flex gap-2 place-self-center bg-d-surface-bg-tertiary p-1.5',
          !isPanelOpen && 'excalidraw:flex-col'
        )}
        style={{ borderRadius: '16px' }}>
        {buttons.map(
          ({ mode, icon, label, condition = true }) =>
            condition && (
              <SimpleButton
                key={mode}
                width={isPanelOpen ? '' : '48px'}
                height={isPanelOpen ? '' : '48px'}
                showLabel={isPanelOpen}
                size='md'
                leftIcon={icon}
                state={currentMode === mode ? 'active' : 'inactive'}
                onClick={() => {
                  setCurrentMode(mode);
                  setIsPanelOpen(true);
                }}>
                {t(label)}
              </SimpleButton>
            )
        )}
      </motion.div>

      {/* HELP BUTTON*/}
      {isPanelOpen && (
        <SimpleButton
          round
          theme='flat'
          variant='tertiary'
          size='squareMD'
          iconSize='16px'
          leftIcon={showHelp ? faTimes : faCircleQuestion}
          onClick={() => {
            setShowHelp((prev) => !prev);
          }}
        />
      )}
    </motion.div>
  );
};

export default PanelNavigation;
