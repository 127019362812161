import {
  BinaryFiles,
  ExcalidrawImperativeAPI,
  ExcalidrawInitialDataState,
} from '@excalidraw/excalidraw/types/types';
import { useStore } from '@nanostores/react';
import { useEffect, useState } from 'react';
import { $excalidrawState, $excalidrawElements } from './excalidraw';
import { ExcalidrawElement, ExcalidrawImageElement } from '@excalidraw/excalidraw/types/element/types';
import { throttle } from 'lodash';

let db: IDBDatabase;
let version = 1;
const dbName = 'files-db';
const storeName = 'files-store';

// Note for dev: DB can be reset with indexedDB.deleteDatabase(dbName)

export const initFilesDB = (): Promise<boolean> => {
  return new Promise((resolve) => {
    // open the connection
    const request = indexedDB.open(dbName);

    request.onupgradeneeded = () => {
      db = request.result;

      // if the data object store doesn't exist, create it
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName);
      }

      // no need to resolve here
    };

    request.onsuccess = () => {
      db = request.result;
      version = db.version;
      resolve(true);
    };

    request.onerror = (err) => {
      console.warn(`failed to init files DB: ${err}`);
      resolve(false);
    };
  });
};

export const useInitIndexedDB = () => {
  const [isDBReady, setIsDBReady] = useState<boolean>(false);

  useEffect(() => {
    initFilesDB()
      .then((status) => setIsDBReady(status))
      .catch((err) => {
        console.warn(err);
      });
  }, []);

  return isDBReady;
};

export let savedFilesIds: string[] = [];

export const setSavedFilesIds = (ids: string[]) => {
  savedFilesIds = ids;
}

export const useGetExcalidrawInitialData = () => {
  const isDBReady = useInitIndexedDB();
  const [initialData, setInitialData] =
    useState<ExcalidrawInitialDataState | null>(null);
  const [initialized, setInitialized] =
    useState<boolean>(false);

  const excalidrawState = useStore($excalidrawState);
  const excalidrawElements = useStore($excalidrawElements);

  const getInitialData = async (): Promise<void> => {
    // No state saved in localStorage, no initial data
    if (!excalidrawState || !isDBReady) return;

    const appState = JSON.parse(excalidrawState);
    appState.collaborators = []; // Prevent "Unexpected Application Error! collaborators.forEach is not a function"
    const elements = JSON.parse(excalidrawElements);

    const storedFiles = await getFiles();

    if (storedFiles) savedFilesIds = Object.keys(storedFiles);

    setInitialData({
      appState: appState,
      elements: elements || [],
      files: storedFiles || {},
    });
    setInitialized(true);
  };

  useEffect(() => {
    getInitialData();
  }, [isDBReady]);

  return {
    initialData,
    initialized,
  };
};

export const getFiles = (): Promise<BinaryFiles> => {
  return new Promise((resolve) => {
    const request = indexedDB.open(dbName);

    request.onsuccess = () => {
      db = request.result;
      const tx = db.transaction(storeName, 'readonly');
      const store = tx.objectStore(storeName);
      const res = store.getAll();
      res.onsuccess = () => {
        resolve(res.result[0]);
      };
    };
  });
};

export const storeFiles = <T>(data: T): Promise<T | string | null> => {
  return new Promise((resolve) => {
    const request = indexedDB.open(dbName, version);

    request.onsuccess = () => {
      db = request.result;
      const tx = db.transaction(storeName, 'readwrite');
      const store = tx.objectStore(storeName);
      store.put(data, storeName); // XXX: could be a key per user in order to handle multi-users
      resolve(data);
    };

    request.onerror = () => {
      const error = request.error?.message;
      if (error) {
        resolve(`failed to store files: ${error}`);
      } else {
        resolve('failed to store files: unknown error');
      }
    };
  });
};

// saveExcalidraw save Excalidraw appSate, elements in local storage
// and files in the indexedDB
export const saveExcalidraw = throttle(async (excalidrawAPI: ExcalidrawImperativeAPI) => {
  if (!excalidrawAPI) return;

  console.debug('save excalidraw data triggered');

  const appState = excalidrawAPI.getAppState();
  const elements = excalidrawAPI.getSceneElements();
  const allFiles = excalidrawAPI.getFiles();

  const files = new Object() as BinaryFiles;

  // Filter out files related to existing images on the canvas as Excalidraw
  // keeps files even when images are deleted (for undo/redo purpose)
  let hasNewFile = false;

  elements
    .filter((element: ExcalidrawElement) => element.type == 'image')
    .forEach((element: ExcalidrawElement) => {
      const image = element as ExcalidrawImageElement;
      const fileId = image.fileId;
      if (fileId) {
        files[fileId] = allFiles[fileId];
        if (!savedFilesIds.includes(fileId as string)) {
          hasNewFile = true;
        }
      }
    });

  // Store state and elements in localStorage
  $excalidrawState.set(JSON.stringify(appState));
  $excalidrawElements.set(JSON.stringify(elements));

  // Store files in indexedDB only if modified (new file or change of size)
  if (hasNewFile || savedFilesIds.length != Object.keys(files).length) {
    console.debug('saving files');
    storeFiles(files)
      .then(() => {
        setSavedFilesIds(Object.keys(files));
        console.debug('files saved');
      })
      .catch((err) => {
        // XXX: maybe handle this error later by alerting the user and
        // inviting them to manually save Excalidraw
        console.warn('files save error:', err);
      });
  }
}, 2000);
