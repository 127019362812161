import { useSync } from '@tldraw/sync';
import { throttle } from 'lodash';
import { CreatorContext } from '../../pages/Creator';
import { motion } from 'framer-motion';
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  AssetRecordType,
  Box,
  createShapeId,
  DefaultColorThemePalette,
  DefaultStylePanel,
  DefaultStylePanelContent,
  DefaultToolbar,
  DefaultToolbarProps,
  Editor,
  exportToBlob,
  TLAsset,
  TLAssetStore,
  TLComponents,
  Tldraw,
  TLDrawShape,
  TLImageAsset,
  TLImageShape,
  TLShape,
  TLUiStylePanelProps,
  uniqueId,
  useEditor,
  useRelevantStyles,
} from 'tldraw';
import 'tldraw/tldraw.css';
// import { HexColorPicker } from 'react-colorful'; /// COLOR PICKER FEATURE ///
import { useWhiteboardAPI } from '@/hooks/whiteboard/useWhiteboardAPI';
import { useKeycloak } from '@react-keycloak/web';
import { useMediaQuery } from '@mui/material';

// Override default palette to force the fill color to be the same
// as the one for contour
DefaultColorThemePalette.lightMode.black.semi =
  DefaultColorThemePalette.lightMode.black.solid;
DefaultColorThemePalette.lightMode.blue.semi =
  DefaultColorThemePalette.lightMode.blue.solid;
DefaultColorThemePalette.lightMode.green.semi =
  DefaultColorThemePalette.lightMode.green.solid;
DefaultColorThemePalette.lightMode.grey.semi =
  DefaultColorThemePalette.lightMode.grey.solid;
DefaultColorThemePalette.lightMode['light-blue'].semi =
  DefaultColorThemePalette.lightMode['light-blue'].solid;
DefaultColorThemePalette.lightMode['light-green'].semi =
  DefaultColorThemePalette.lightMode['light-green'].solid;
DefaultColorThemePalette.lightMode['light-red'].semi =
  DefaultColorThemePalette.lightMode['light-red'].solid;
DefaultColorThemePalette.lightMode['light-violet'].semi =
  DefaultColorThemePalette.lightMode['light-violet'].solid;
DefaultColorThemePalette.lightMode.orange.semi =
  DefaultColorThemePalette.lightMode.orange.solid;
DefaultColorThemePalette.lightMode.red.semi =
  DefaultColorThemePalette.lightMode.red.solid;
DefaultColorThemePalette.lightMode.violet.semi =
  DefaultColorThemePalette.lightMode.violet.solid;
DefaultColorThemePalette.lightMode.white.semi =
  DefaultColorThemePalette.lightMode.white.solid;
DefaultColorThemePalette.lightMode.yellow.semi =
  DefaultColorThemePalette.lightMode.yellow.solid;

DefaultColorThemePalette.darkMode.black.semi =
  DefaultColorThemePalette.darkMode.black.solid;
DefaultColorThemePalette.darkMode.blue.semi =
  DefaultColorThemePalette.darkMode.blue.solid;
DefaultColorThemePalette.darkMode.green.semi =
  DefaultColorThemePalette.darkMode.green.solid;
DefaultColorThemePalette.darkMode.grey.semi =
  DefaultColorThemePalette.darkMode.grey.solid;
DefaultColorThemePalette.darkMode['light-blue'].semi =
  DefaultColorThemePalette.darkMode['light-blue'].solid;
DefaultColorThemePalette.darkMode['light-green'].semi =
  DefaultColorThemePalette.darkMode['light-green'].solid;
DefaultColorThemePalette.darkMode['light-red'].semi =
  DefaultColorThemePalette.darkMode['light-red'].solid;
DefaultColorThemePalette.darkMode['light-violet'].semi =
  DefaultColorThemePalette.darkMode['light-violet'].solid;
DefaultColorThemePalette.darkMode.orange.semi =
  DefaultColorThemePalette.darkMode.orange.solid;
DefaultColorThemePalette.darkMode.red.semi =
  DefaultColorThemePalette.darkMode.red.solid;
DefaultColorThemePalette.darkMode.violet.semi =
  DefaultColorThemePalette.darkMode.violet.solid;
DefaultColorThemePalette.darkMode.white.semi =
  DefaultColorThemePalette.darkMode.white.solid;
DefaultColorThemePalette.darkMode.yellow.semi =
  DefaultColorThemePalette.darkMode.yellow.solid;

const COLLAB_SERVER_URL = import.meta.env.VITE_COLLAB_SERVER_URL;

interface SelectionWatcherProps {
  onSelectionChange: (
    hasObjectSelected: boolean,
    hasImageSelected: boolean,
    hasDrawingSelected: boolean
  ) => void;
}

/// COLOR PICKER FEATURE ///
// Apply the meta color of the shape
// const applyMetaColor = (shape: TLShape) => {
//   const color = shape.meta.color as string;
//   const svg = document.querySelector('[data-shape-id="' + shape.id + '"]');
//   const paths = svg?.querySelectorAll('path');
//   paths?.forEach((path) => {
//     if (shape.type === 'draw') path.setAttribute('fill', color as string);
//     if (shape.type === 'geo') {
//       const geoShape = shape as TLGeoShape;
//       if (geoShape.props.fill === 'solid') path.setAttribute('fill', color as string);
//       else path.setAttribute('fill', 'none');
//     }

//     path.setAttribute('stroke', color as string);
//   });
// };

/// COLOR PICKER FEATURE ///
// const ColorPicker: React.FC<{ color: string }> = ({ color }) => {
//   const editor = useEditor();
//   console.debug("NEW COLOR", color);
//   useEffect(() => {
//     const disableBefore = editor.sideEffects.registerBeforeCreateHandler('shape', (shape) => {
//       if (!shape.meta.color) {
//         console.debug("SET META COLOR TO", color);
//         shape.meta.color = color;
//       }

//       return shape;
//     });

//     // const disableAfter = editor.sideEffects.registerAfterCreateHandler('shape', (shape) => {
//     //   if (!shape.meta.color) return;
//     //   console.debug("SET SVG COLOR TO", shape.meta.color);
//     //   applyMetaColor(shape);
//     // });

//     // Change the color on selected shapes
//     editor.getSelectedShapes().forEach((shape) => {
//       editor.updateShape({
//         id: shape.id,
//         type: shape.type,
//         meta: { color }
//       })
//     })

//     return () => {
//       disableBefore();
//       //disableAfter();
//     }
//   }, [color])

//   return null;
// };

const SelectionWatcher: React.FC<SelectionWatcherProps> = ({
  onSelectionChange,
}) => {
  const editor = useEditor();
  useEffect(() => {
    if (!editor) return;

    // Define a throttled callback
    const throttledSelectionChange = throttle(() => {
      const hasSelected = editor.getSelectedShapeIds().length > 0;
      let hasImageSelected = false;
      let hasDrawingSelected = false;
      editor.getSelectedShapes().forEach((shape) => {
        if (shape.type === 'image') hasImageSelected = true;
        else if (shape.type === 'draw') hasDrawingSelected = true;
      });

      onSelectionChange(hasSelected, hasImageSelected, hasDrawingSelected);
    }, 300); // Throttle interval in milliseconds

    // Subscribe to state changes
    const unsubscribe = editor.store.listen(() => throttledSelectionChange());

    // Run once on mount to initialize
    throttledSelectionChange();

    // Cleanup on unmount
    return () => unsubscribe();
  }, [editor, onSelectionChange]);

  return null;
};

const components: TLComponents = {
  //ContextMenu: CustomContextMenu,
  //SharePanel: CustomSharePanel,
  StylePanel: CustomStylePanel,
  Toolbar: CustomToolbar,
};

// function CustomSharePanel() {
//   return (
//     <div
//       style={{
//         position: 'absolute',
//         top: 50,
//         left: 0,
//       }}>
//       <DefaultSharePanel />
//     </div>
//   );
// }

function CustomStylePanel(props: TLUiStylePanelProps) {
  const styles = useRelevantStyles();

  if (props.isMobile) {
    return (
      <DefaultStylePanel {...props}>
        <DefaultStylePanelContent styles={styles} />
      </DefaultStylePanel>
    );
  }

  return (
    <div
      style={{
        position: 'absolute',
        top: 200,
        left: 0,
      }}>
      <DefaultStylePanel {...props}>
        <DefaultStylePanelContent styles={styles} />
      </DefaultStylePanel>
    </div>
  );
}

function CustomToolbar(props: DefaultToolbarProps) {
  const isMobile = useMediaQuery('(max-width: 729px)');

  if (isMobile) {
    return (
      <div
        style={{
          position: 'relative',
          bottom: 90,
          left: 0,
        }}>
        <DefaultToolbar {...props} />
      </div>
    );
  }

  return <DefaultToolbar {...props} />;
}

// function CustomContextMenu(props: TLUiContextMenuProps) {
//   const exportSelectionAsPng = useExportCanvas();

// 	return (
// 		<DefaultContextMenu {...props}>
// 			{/* <TldrawUiMenuGroup id="export">
// 				<div>
// 					<TldrawUiMenuItem
// 						id="export-png"
// 						label="Export as PNG"
// 						icon="external-link"
// 						readonlyOk
// 						onSelect={() => {
//               exportSelectionAsPng();
//             }}
// 					/>
// 				</div>
// 			</TldrawUiMenuGroup> */}
// 			{/* <DefaultContextMenuContent /> */}
//       <TldrawUiMenuGroup id="modify">
// 				<EditMenuSubmenu />
// 				<ArrangeMenuSubmenu />
// 				<ReorderMenuSubmenu />
// 			</TldrawUiMenuGroup>
//       <ClipboardMenuGroup />
//       <ConversionsMenuGroup />
// 			<TldrawUiMenuGroup id="select-all">
// 				<SelectAllMenuItem />
// 			</TldrawUiMenuGroup>
// 		</DefaultContextMenu>
// 	)
// }

const useMultiplayerAssets = (editorRef: MutableRefObject<Editor | null>, whiteboardId: string) => {
  const { addWhiteboardImage, getWhiteboardImage } = useWhiteboardAPI();

  const uploadAsset = async (asset: TLAsset, file: File): Promise<string> => {
    let assetUuid;
    if (asset.meta.uuid) assetUuid = asset.meta.uuid.toString();
    else assetUuid = crypto.randomUUID();

    try {
      console.debug("upload image", assetUuid, "to wb", whiteboardId);
      await addWhiteboardImage(whiteboardId, assetUuid, file);
    } catch (err) {
      throw new Error(`failed to upload image asset: ${err}`);
    }

    // In case the asset already exists, use the editor to edit
    // its props
    let existingAsset = null;
    if (editorRef.current) {
      existingAsset = editorRef.current.getAsset(asset.id);
      if (existingAsset) {
        editorRef.current.updateAssets([{
          id: asset.id,
          type: asset.type,
          meta: {
            uuid: assetUuid,
            whiteboardId: whiteboardId,
          }
        }]);
      }
    }

    // In case the asset does not exist yet, directly edit
    // its props
    try {
      asset.meta.uuid = assetUuid;
      asset.meta.whiteboardId = whiteboardId;
    } catch (err) { /* ignore readonly error */ }

    // Only a placeholder as resolveAsset is used instead of this URL
    return 'http://placeholder';
  };

  const resolveAsset = async (asset: TLAsset): Promise<string> => {
    if (asset.props.src?.startsWith('data:')) {
      console.debug("data: found in props.src");
      return asset.props.src;
    }

    if (!asset.meta.uuid) {
      console.debug("no uuid found in meta");
      return '';
    }

    try {
      console.debug("download image", asset.meta.uuid, "from wb", asset.meta.whiteboardId as string || whiteboardId);
      const blob = await getWhiteboardImage(
        asset.meta.whiteboardId as string || whiteboardId,
        asset.meta.uuid as string
      );

      // Image coming from another whiteboard (copy/pasted):
      // upload it under this whiteboard
      if (asset.meta.whiteboardId?.toString() !== whiteboardId) {
        uploadAsset(asset, new File([blob], asset.meta.uuid as string, { type: blob.type }));
      }

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = async () => {
          try {
            const dataImg = reader.result?.toString() || '';
            resolve(dataImg);
          } catch (err) {
            reject(err);
          }
        };

        reader.readAsDataURL(blob);
      });
    } catch (err) {
      throw new Error(`failed to fetch image asset: ${err}`);
    }
  };

  // How does our server handle assets like images and videos?
  return useMemo<TLAssetStore>(
    () => ({
      upload: uploadAsset,
      resolve: resolveAsset,
    }),
    []
  );
};

export default function TldrawWhiteboard({
  children,
  whiteboardId,
}: {
  children: React.ReactNode;
  whiteboardId: string;
}) {
  const [editor, setEditor] = useState<Editor | null>(null);
  const editorRef = useRef<Editor | null>(null); // Ref to store the latest editor instance
  const { keycloak } = useKeycloak();
  const multiplayerAssets = useMultiplayerAssets(editorRef, whiteboardId);

  const getURI = useCallback(async () => {
    return `${COLLAB_SERVER_URL}/connect/${whiteboardId}?token=${keycloak.token}`;
  }, [whiteboardId, keycloak]);

  const store = useSync({
    uri: getURI,
    assets: multiplayerAssets,
  });

  const [hasObjectSelected, setHasObjectSelected] = useState<boolean>(false);
  const [hasImageSelected, setHasImageSelected] = useState<boolean>(false);
  const [hasDrawingSelected, setHasDrawingSelected] = useState<boolean>(false);
  // const [previewImage, setPreviewImage] = useState<PreviewImage | null>(null);
  // const [pointerPosition, setPointerPosition] = useState({ x: 0, y: 0 });
  // const importImageCallbackRef = useRef<((elementId: string) => void) | null>(
  //   null
  // ); // Ref to hold the callback

  // const handleExportPreviewImage = throttle(async (): Promise<void> => {
  //   if (exportPreviewImageCallbackRef.current) {
  //     exportPreviewImageCallbackRef.current();
  //   } else {
  //     console.error('exportPreviewImageCallbackRef is not initialized');
  //   }
  // }, 10000);

  const { saveWhiteboardPreviewImage } = useWhiteboardAPI();

  const exportPreviewImage = useCallback(
    throttle(async (): Promise<void> => {
      if (!editorRef.current) {
        console.debug('no editor for export preview image');
        return;
      }

      // Get all selected element IDs
      const shapesIds = editorRef.current.getCurrentPageShapeIds();

      if (shapesIds.size === 0) {
        return;
      }

      try {
        // Use exportToBlob to generate the image Blob
        const blob = await exportToBlob({
          editor: editorRef.current,
          format: 'jpeg',
          ids: Array.from(shapesIds),
          opts: {
            padding: 0,
            bounds: editorRef.current.getViewportPageBounds(),
            quality: 0.6,
          },
        });

        if (!blob) {
          console.error('failed to generate preview image blob');
          return;
        }

        saveWhiteboardPreviewImage(whiteboardId, blob)
          .then(() => {
            console.debug('preview image saved');
          })
          .catch((err) => {
            console.error(`failed to save whiteboard preview image: ${err}`);
          });
      } catch (error) {
        console.error(`error exporting preview image: ${error}`);
        return;
      }
    }, 10000),
    []
  );

  const exportForImagine = useCallback(async (): Promise<string> => {
    if (!editor) {
      console.error('Editor is not initialized');
      return '';
    }

    // Get all selected element IDs
    const selectedIds = editor.getSelectedShapeIds();

    if (selectedIds.length === 0) {
      console.error('No elements selected');
      return '';
    }

    try {
      // Use exportToBlob to generate the image Blob
      const blob = await exportToBlob({
        editor: editor,
        format: 'png',
        ids: selectedIds,
        opts: {
          padding: 0,
        },
      });

      if (!blob) {
        console.error('Failed to generate image blob');
        return '';
      }

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = async () => {
          try {
            const dataImg = reader.result?.toString() || '';
            resolve(dataImg);
          } catch (err) {
            reject(err);
          }
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error exporting image:', error);
      return '';
    }
  }, [editor]);

  const exportForInpainting = useCallback(async (): Promise<string[]> => {
    if (!editor) {
      console.error('Editor is not initialized');
      return [];
    }

    // Get all selected element IDs
    const selectedIds = editor.getSelectedShapeIds();

    if (selectedIds.length === 0) {
      console.error('No elements selected');
      return [];
    }

    const selectedShapes = editor.getSelectedShapes();
    const selectedImages = selectedShapes.filter((shape) => {
      return shape.type === 'image';
    });
    if (!selectedImages.length) return [];

    const bounds = computeImagesBounds(selectedImages);

    const drawings = selectedShapes
      .filter((shape) => {
        return shape.type === 'draw';
      })
      .map((shape: TLShape) => {
        const draw = shape as TLDrawShape;

        //console.debug("SHAPE", draw);

        // Shape is not closed, join the first point with the last
        if (!draw.props.isClosed) {
          //console.debug("unclosed draw");
          const segment = draw.props.segments[0];
          const newSegment = { ...draw.props.segments[0] };
          newSegment.points = [...segment.points, segment.points[0]];

          // Maybe rollback the update (or not?)
          editor.updateShape({
            id: draw.id,
            type: draw.type,
            props: {
              segments: [newSegment],
              isClosed: true,
            },
          });
        }

        return draw;
      }, []);

    // Create canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) return [];

    // Set canvas size
    canvas.width = bounds.w;
    canvas.height = bounds.h;

    // Get shapes position in bounding box space
    const shapesInBounds = drawings.map((shape) => {
      const x = shape.x - bounds.x;
      const y = shape.y - bounds.y;
      return { ...shape, x, y };
    });

    // Get the svg for each shape
    const svgstoDraw: SVGElement[] = [];

    for (let i = 0; i < shapesInBounds.length; i++) {
      const shape = shapesInBounds[i];
      const fillValue = (shape as TLDrawShape).props.fill;

      // Fill the drawing
      editor.updateShape({
        id: shape.id,
        type: shape.type,
        props: { fill: 'solid' },
      });

      const svg = await editor.getSvgElement([shape.id], {
        bounds: bounds,
        padding: 0,
      });

      // Reset fill value
      editor.updateShape({
        id: shape.id,
        type: shape.type,
        props: { fill: fillValue },
      });

      if (!svg) continue;
      svgstoDraw.push(svg.svg);
    }

    svgstoDraw.forEach((svg) => {
      const paths = svg.querySelectorAll('path');
      paths.forEach((path) => {
        path.setAttribute('stroke', 'white');
        path.setAttribute('fill', 'white');
      });
    });

    // convert every svg to an image
    const images = await Promise.all(
      svgstoDraw.map((svg) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.onload = () => {
            resolve(img);
          };
          img.src = `data:image/svg+xml;base64,${btoa(
            new XMLSerializer().serializeToString(svg)
          )}`;
        });
      })
    );

    // draw black background
    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, bounds.w, bounds.h);

    //draw the images on the canvas
    images.forEach((img) => {
      if (!ctx) return;

      ctx.drawImage(img as CanvasImageSource, 0, 0);
    });

    try {
      // Use exportToBlob to generate the image Blob
      const blob = await exportToBlob({
        editor: editor,
        format: 'png',
        ids: selectedImages.map((s) => s.id),
        opts: {
          padding: 0,
        },
      });

      if (!blob) {
        console.error('Failed to generate image blob');
        return [];
      }

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = async () => {
          try {
            const dataImg = reader.result?.toString() || '';
            resolve([dataImg, canvas.toDataURL('image/png')]);
          } catch (err) {
            reject(err);
          }
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error exporting image:', error);
      return [];
    }
  }, [editor]);

  const importImageFromDOMElement = useCallback(
    async (elementId: string) => {
      if (!editor) return;

      const imgElement = document.getElementById(elementId) as HTMLImageElement;

      if (!imgElement || imgElement.tagName !== 'IMG') {
        console.error(`No <img> element found with ID "${elementId}"`);
        return;
      }

      const imageUrl = imgElement.src;

      const img = new Image();
      img.crossOrigin = 'anonymous'; // Handle CORS
      img.src = imageUrl;

      img.onload = async () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0);
        const imgFile = await canvasToFile(canvas);

        const assetId = AssetRecordType.createId();
        const asset = {
          id: assetId,
          type: 'image',
          typeName: 'asset',
          props: {
            name: assetId as string,
            w: img.width,
            h: img.height,
            mimeType: 'image/png',
            isAnimated: false,
            src: await fileToDataURL(imgFile),
          },
          meta: {
            uuid: crypto.randomUUID(),
            whiteboardId: whiteboardId,
          },
        } as TLImageAsset;
        editor.createAssets([asset]);

        const viewBounds = editor.getViewportPageBounds();
        const imageRatio = img.width / img.height;
        const w = viewBounds.w / 3;

        // Create image shape
        const imageId = createShapeId();
        editor.createShape({
          id: imageId,
          type: 'image',
          x: viewBounds.x + viewBounds.w / 20,
          y: viewBounds.y + viewBounds.h / 20,
          props: {
            assetId,
            w: w,
            h: w / imageRatio,
          },
        });

        editor.setCurrentTool('select');
        editor.select(imageId);
        multiplayerAssets.upload(asset, imgFile);
      };

      img.onerror = () => {
        alert('Failed to load the image');
      };
    },
    [editor]
  );

  // const handleExportForImagine = async (): Promise<string> => {
  //   if (exportForImagineCallbackRef.current) {
  //     return exportForImagineCallbackRef.current();
  //   } else {
  //     console.error('exportForImagineCallbackRef is not initialized');
  //     return '';
  //   }
  // };

  // const handleExportForInpaint = async (): Promise<string[]> => {
  //   if (exportForInpaintCallbackRef.current) {
  //     return exportForInpaintCallbackRef.current();
  //   } else {
  //     console.error('exportForInpaintCallbackRef is not initialized');
  //     return [];
  //   }
  // };

  const handleSelectionChange = (
    hasObjectSelected: boolean,
    hasImageSelected: boolean,
    hasDrawingSelected: boolean
  ) => {
    setHasObjectSelected(hasObjectSelected);
    setHasImageSelected(hasImageSelected);
    setHasDrawingSelected(hasDrawingSelected);
  };

  // const handleImageClick = (elementId: string) => {
  //   // Check if the ref has been set
  //   if (importImageCallbackRef.current) {
  //     // Call the function stored in the ref with the element ID
  //     importImageCallbackRef.current(elementId);
  //   } else {
  //     console.error('importImageFromDOMElement is not initialized');
  //   }
  // };

  // useEffect(() => {
  //   return () => {
  //     if (previewImage?.src.startsWith('blob:')) {
  //       URL.revokeObjectURL(previewImage.src);
  //     }
  //   };
  // }, [previewImage]);

  /// COLOR PICKER FEATURE ///
  // const [color, setColor] = useState('#f875ff');

  const onChange = useCallback(() => {
    if (!editorRef.current) {
      console.debug('No editor available');
      return;
    }

    exportPreviewImage();
  }, [exportPreviewImage]);

  return (
    <div className='relative grid h-dvh w-screen overflow-hidden md:grid-rows-1'>
      <Tldraw
        options={{ maxPages: 1 }}
        store={store}
        components={components}
        onMount={(editorInstance) => {
          setEditor(editorInstance);
          editorRef.current = editorInstance;
          editorInstance.on('event', () => onChange());

          const interval = setInterval(() => {
            editorInstance.getCurrentPageShapes()
              .filter((shape) => shape.type == 'draw')
              .forEach((shape: TLShape) => {
                const drawShape = shape as TLDrawShape;
                if (!drawShape.props.isClosed && drawShape.props.fill === 'solid') {
                  editorInstance.updateShape({
                    id: drawShape.id,
                    type: drawShape.type,
                    props: { isClosed: true }
                  });
                }
              });
          }, 500);

          return () => {
            clearInterval(interval);
          };

          /// COLOR PICKER FEATURE ///
          // Go through all shapes to apply the meta color
          /*
          const interval = setInterval(() => {
            editor.getCurrentPageShapes().forEach((shape) => {
              applyMetaColor(shape);
            });
          }, 100);

          return () => {
            clearInterval(interval);
          }; */
          /// END ///
        }}
        onUiEvent={(event) => {
          if (!editor) return;
          console.debug('UI EVENT', event);

          /// COLOR PICKER FEATURE ///
          /* setTimeout(() => {
            editor.getCurrentPageShapes().forEach((shape) => {
              applyMetaColor(shape);
            });
          }, 10); //very hacky, without the timeout the currentPage is the one we are leaving */
          /// END ///
        }}>
        <SelectionWatcher onSelectionChange={handleSelectionChange} />
        {/* /// COLOR PICKER FEATURE /// <ColorPicker color={color} /> */}

        {/* <ImageImporter
          whiteboardId={whiteboardId}
          setImportImageCallback={(callback) =>
            (importImageCallbackRef.current = callback)
          }
          pointerPosition={pointerPosition}
          setPointerPosition={setPointerPosition}
          previewImage={previewImage}
          setPreviewImage={setPreviewImage}
        /> */}
        {/* <ImageExporter
          whiteboardId={whiteboardId}
          setExportPreviewImageCallback={(callback) =>
            (exportPreviewImageCallbackRef.current = callback)
          }
          setExportForImagineCallback={(callback) =>
            (exportForImagineCallbackRef.current = callback)
          }
          setExportForInpaintCallback={(callback) =>
            (exportForInpaintCallbackRef.current = callback)
          }
        /> */}
      </Tldraw>

      <motion.div
        layout
        className='pointer-events-none absolute z-[500] flex h-full w-full items-end justify-end overflow-hidden'>
        <CreatorContext.Provider
          value={{
            hasObjectSelected,
            hasImageSelected,
            hasDrawingSelected,
            // hasSelectedDrawingsOutsideSelectedImages,
            importImageFromDOMElement,
            exportForImagine,
            exportForInpainting,
          }}>
          {children}
        </CreatorContext.Provider>
      </motion.div>

      {/* Image Preview */}
      {/* {previewImage && (
        <div
          style={{
            position: 'absolute',
            left: pointerPosition.x,
            top: pointerPosition.y,
            width: previewImage.width,
            height: previewImage.height,
            transform: 'translate(0%, -50%)',
            opacity: 0.5,
            pointerEvents: 'none',
            backgroundImage: `url(${previewImage.src})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            zIndex: 1000,
          }}
        />
      )} */}
    </div>
  );
}

const fileToDataURL = async (file: File): Promise<string | null> => {
  return new Promise((resolve, reject) => {
      const reader = new FileReader();

      // When the read is complete, resolve the promise with the dataURL
      reader.onloadend = () => resolve(reader.result as string);

      // Handle errors
      reader.onerror = reject;

      // Read the file as a dataURL
      reader.readAsDataURL(file);
  });
}

// function useExportCanvas() {
//   const editor = useEditor();

//   const exportSelectionAsPng = async () => {
//     const shapes = editor.getCurrentPageShapesSorted();

//     //create canvas
//     const canvas = document.createElement('canvas');
//     const ctx = canvas.getContext('2d');
//     if (!ctx) return;

//     const bounds = editor.getSelectionPageBounds();

//     if (!bounds) return;

//     // Set canvas size
//     canvas.width = bounds.w;
//     canvas.height = bounds.h;

//     //Get shapes position in bounding box space
//     const shapesInBounds = shapes.map((shape) => {
//       const x = shape.x - bounds.x;
//       const y = shape.y - bounds.y;
//       return { ...shape, x, y };
//     });

//     //get the svg for each shape
//     const svgstoDraw: SVGElement[] = [];

//     for (let i = 0; i < shapesInBounds.length; i++) {
//       const shape = shapesInBounds[i];
//       const svg = await editor.getSvgElement([shape.id], {
//         bounds: bounds,
//         padding: 0,
//       });

//       if (!svg) continue;
//       svgstoDraw.push(svg.svg);
//     }

//     //the paths are in the innerHtml of the svg element, find the stroke and fill parameters and replace them with the color in the shape.meta.color
//     svgstoDraw.forEach((svg, index) => {
//       const paths = svg.querySelectorAll('path');
//       paths.forEach((path) => {
//         path.setAttribute(
//           'stroke',
//           shapesInBounds[index].meta.color as string
//         );
//         path.setAttribute(
//           'fill',
//           shapesInBounds[index].meta.color as string
//         );
//       });
//     });

//     //convert every svg to an image
//     const images = await Promise.all(
//       svgstoDraw.map((svg) => {
//         return new Promise((resolve) => {
//           const img = new Image();
//           img.onload = () => {
//             resolve(img);
//           };
//           img.src = `data:image/svg+xml;base64,${btoa(
//             new XMLSerializer().serializeToString(svg)
//           )}`;
//         });
//       })
//     );

//     //draw white background
//     ctx.fillStyle = 'white';
//     ctx.fillRect(0, 0, bounds.w, bounds.h);

//     //draw the images on the canvas
//     images.forEach((img) => {
//       if (!ctx) return;

//       ctx.drawImage(img as CanvasImageSource, 0, 0);
//     });

//     //export the canvas as image
//     const dataUrl = canvas.toDataURL('image/png');
//     const link = document.createElement('a');
//     link.href = dataUrl;
//     link.download = 'every-shape-on-the-canvas.png';
//     link.click();
//   }

//   return (exportSelectionAsPng);

//   // return (
//   //   <button
//   //     className='absolute bottom-0 right-0 z-[500] p-2'
//   //     style={{ pointerEvents: 'all', fontSize: 18, backgroundColor: 'thistle' }}
//   //     onClick={exportSelectionAsPng}>
//   //     Export canvas as image
//   //   </button>
//   // );
// }

// type PreviewImage = {
//   height: number;
//   width: number;
//   src: string;
//   file: File;
// };

// const ImageImporter: React.FC<{
//   whiteboardId: string;
//   setImportImageCallback: (callback: (elementId: string) => void) => void;
//   pointerPosition: { x: number; y: number };
//   setPointerPosition: React.Dispatch<
//     React.SetStateAction<{ x: number; y: number }>
//   >;
//   previewImage: PreviewImage | null;
//   setPreviewImage: React.Dispatch<React.SetStateAction<PreviewImage | null>>;
// }> = ({
//   whiteboardId,
//   setImportImageCallback,
//   pointerPosition,
//   setPointerPosition,
//   previewImage,
//   setPreviewImage,
// }) => {
//   const editor = useEditor();
//   const multiplayerAssets = useMultiplayerAssets(whiteboardId);
//   useEffect(() => {
//     const importImageFromDOMElement = (elementId: string) => {
//       const imgElement = document.getElementById(elementId) as HTMLImageElement;

//       if (!imgElement || imgElement.tagName !== 'IMG') {
//         console.error(`No <img> element found with ID "${elementId}"`);
//         return;
//       }

//       const imageUrl = imgElement.src;

//       const img = new Image();
//       img.crossOrigin = 'anonymous'; // Handle CORS
//       img.src = imageUrl;

//       img.onload = async () => {
//         const canvas = document.createElement('canvas');
//         canvas.width = img.width;
//         canvas.height = img.height;
//         const ctx = canvas.getContext('2d');
//         ctx?.drawImage(img, 0, 0);
//         const imgFile = await canvasToFile(canvas);

//         const assetId = AssetRecordType.createId()
//         const asset = {
//           id: assetId,
//           type: 'image',
//           typeName: 'asset',
//           props: {
//             name: assetId as string,
//             w: img.width,
//             h: img.height,
//             mimeType: 'image/png',
//             isAnimated: false,
//           },
//           meta: {},
//         } as TLAsset;

//         multiplayerAssets.upload(asset, imgFile).then((url) => {
//           asset.props.src = url;
//           editor.createAssets([asset]);
//         });

//         const viewBounds = editor.getViewportPageBounds();
//         const imageRatio = img.width / img.height;
//         const w = viewBounds.w/3;

//         // Create image shape
//         editor.createShape({
//           type: 'image',
//           x: viewBounds.x + viewBounds.w/20,
//           y: viewBounds.y + viewBounds.h/20,
//           props: {
//             assetId,
//             w: w,
//             h: w / imageRatio,
//           },
//         });
//       };

//       img.onerror = () => {
//         alert('Failed to load the image');
//       };
//     };

//     // Pass the callback to the parent
//     setImportImageCallback(importImageFromDOMElement);
//   }, [setImportImageCallback, setPreviewImage]);

//   useEffect(() => {
//     if (!editor || !previewImage) return;

//     const handlePointerMove = (event: PointerEvent) => {
//       const { x, y } = editor.screenToPage({
//         x: event.clientX,
//         y: event.clientY,
//       });
//       setPointerPosition({ x, y });
//     };

//     // Pointer up: add the preview image to the canvas
//     const handlePointerUp = () => {
//       if (!previewImage) return;

//       const assetId = AssetRecordType.createId()
//       const asset = {
//         id: assetId,
//         type: 'image',
//         typeName: 'asset',
//         props: {
//           name: assetId as string,
//           //src: null,
//           w: previewImage.width,
//           h: previewImage.height,
//           mimeType: 'image/png',
//           isAnimated: false,
//         },
//         meta: {},
//       } as TLAsset;

//       multiplayerAssets.upload(asset, previewImage.file).then((url) => {
//         asset.props.src = url;
//         editor.createAssets([asset]);
//       });

//       // Create image shape
//       editor.createShape({
//         type: 'image',
//         x: pointerPosition.x,
//         y: pointerPosition.y,
//         props: {
//           assetId,
//           w: previewImage.width,
//           h: previewImage.height,
//         },
//       });

//       setPreviewImage(null); // Clear the preview image
//     };

//     // Attach event listeners
//     document.addEventListener('pointermove', handlePointerMove);
//     document.addEventListener('pointerup', handlePointerUp);

//     return () => {
//       // Clean up event listeners
//       document.removeEventListener('pointermove', handlePointerMove);
//       document.removeEventListener('pointerup', handlePointerUp);
//     };
//   }, [
//     editor,
//     previewImage,
//     pointerPosition,
//     setPointerPosition,
//     setPreviewImage,
//   ]);

//   return null;
// };

const canvasToFile = async (
  canvas: HTMLCanvasElement,
  fileName: string = uniqueId(),
  mimeType = 'image/png'
): Promise<File> => {
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        const file = new File([blob], fileName, { type: mimeType });
        resolve(file);
      } else {
        reject(new Error('Failed to convert canvas to Blob.'));
      }
    }, mimeType);
  });
};

// const ImageExporter: React.FC<{
//   whiteboardId: string;
//   setExportPreviewImageCallback: (callback: () => Promise<void>) => void;
//   setExportForImagineCallback: (callback: () => Promise<string>) => void;
//   setExportForInpaintCallback: (callback: () => Promise<string[]>) => void;
//   }> = ({
//     whiteboardId,
//     setExportPreviewImageCallback,
//     setExportForImagineCallback,
//     setExportForInpaintCallback,
//   }) => {
//   const editor = useEditor();
//   const { saveWhiteboardPreviewImage } = useWhiteboardAPI();

//   useEffect(() => {
//     const exportPreviewImage = async (): Promise<void> => {
//       if (!editor) {
//         console.error('Editor is not initialized');
//         return;
//       }

//       // Get all selected element IDs
//       const shapesIds = editor.getCurrentPageShapeIds();

//       if (shapesIds.size === 0) {
//         return;
//       }

//       try {
//         // Use exportToBlob to generate the image Blob
//         const blob = await exportToBlob({
//           editor: editor,
//           format: 'jpeg',
//           ids: Array.from(shapesIds),
//           opts: {
//             padding: 0,
//             bounds: editor.getViewportPageBounds(),
//             quality: 0.6
//           },
//         });

//         if (!blob) {
//           console.error('failed to generate preview image blob');
//           return;
//         }

//         saveWhiteboardPreviewImage(whiteboardId, blob)
//           .then(() => {
//             console.debug('preview image saved');
//           })
//           .catch((err) => {
//             console.error(`failed to save whiteboard preview image: ${err}`);
//           });

//       } catch (error) {
//         console.error(`error exporting preview image: ${error}`);
//         return;
//       }
//     };

//     const exportForImagine = async (): Promise<string> => {
//       if (!editor) {
//         console.error('Editor is not initialized');
//         return '';
//       }

//       // Get all selected element IDs
//       const selectedIds = editor.getSelectedShapeIds();

//       if (selectedIds.length === 0) {
//         console.error('No elements selected');
//         return '';
//       }

//       try {
//         // Use exportToBlob to generate the image Blob
//         const blob = await exportToBlob({
//           editor: editor,
//           format: 'png',
//           ids: selectedIds,
//           opts: {
//             padding: 0,
//           },
//         });

//         if (!blob) {
//           console.error('Failed to generate image blob');
//           return '';
//         }

//         return new Promise((resolve, reject) => {
//           const reader = new FileReader();
//           reader.onloadend = async () => {
//             try {
//               const dataImg = reader.result?.toString() || '';
//               resolve(dataImg);
//             } catch (err) {
//               reject(err);
//             }
//           };
//           reader.readAsDataURL(blob);
//         });
//       } catch (error) {
//         console.error('Error exporting image:', error);
//         return '';
//       }
//     };

//     const exportForInpaint = async (): Promise<string[]> => {
//       if (!editor) {
//         console.error('Editor is not initialized');
//         return [];
//       }

//       // Get all selected element IDs
//       const selectedIds = editor.getSelectedShapeIds();

//       if (selectedIds.length === 0) {
//         console.error('No elements selected');
//         return [];
//       }

//       const selectedShapes = editor.getSelectedShapes();
//       const selectedImages = selectedShapes.filter((shape) => {
//         return shape.type === 'image';
//       });
//       if (!selectedImages.length) return [];

//       const bounds = computeImagesBounds(selectedImages);

//       const drawings = selectedShapes
//         .filter((shape) => {
//           return shape.type === 'draw';
//         })
//         .map((shape: TLShape) => {
//           const draw = shape as TLDrawShape;

//           //console.debug("SHAPE", draw);

//           // Shape is not closed, join the first point with the last
//           if (!draw.props.isClosed) {
//               //console.debug("unclosed draw");
//               const segment = draw.props.segments[0];
//               const newSegment = {...draw.props.segments[0]}
//               newSegment.points = [...segment.points, segment.points[0]]

//               // Maybe rollback the update (or not?)
//               editor.updateShape({ id: draw.id, type: draw.type, props: {
//                 segments: [newSegment],
//                 isClosed: true
//               } });
//             }

//           return draw;
//         }, []);

//       // Create canvas
//       const canvas = document.createElement('canvas');
//       const ctx = canvas.getContext('2d');
//       if (!ctx) return [];

//       // Set canvas size
//       canvas.width = bounds.w;
//       canvas.height = bounds.h;

//       // Get shapes position in bounding box space
//       const shapesInBounds = drawings.map((shape) => {
//         const x = shape.x - bounds.x;
//         const y = shape.y - bounds.y;
//         return { ...shape, x, y };
//       });

//       // Get the svg for each shape
//       const svgstoDraw: SVGElement[] = [];

//       for (let i = 0; i < shapesInBounds.length; i++) {
//         const shape = shapesInBounds[i];
//         const fillValue = (shape as TLDrawShape).props.fill;

//         // Fill the drawing
//         editor.updateShape({
//           id: shape.id,
//           type: shape.type,
//           props: { fill: 'solid' }
//         })

//         const svg = await editor.getSvgElement([shape.id], {
//           bounds: bounds,
//           padding: 0,
//         });

//         // Reset fill value
//         editor.updateShape({
//           id: shape.id,
//           type: shape.type,
//           props: { fill: fillValue }
//         })

//         if (!svg) continue;
//         svgstoDraw.push(svg.svg);
//       }

//       svgstoDraw.forEach((svg) => {
//         const paths = svg.querySelectorAll('path');
//         paths.forEach((path) => {
//           path.setAttribute(
//             'stroke',
//             'white'
//           );
//           path.setAttribute(
//             'fill',
//             'white'
//           );
//         });
//       });

//       //convert every svg to an image
//       const images = await Promise.all(
//         svgstoDraw.map((svg) => {
//           return new Promise((resolve) => {
//             const img = new Image();
//             img.onload = () => {
//               resolve(img);
//             };
//             img.src = `data:image/svg+xml;base64,${btoa(
//               new XMLSerializer().serializeToString(svg)
//             )}`;
//           });
//         })
//       );

//       // draw black background
//       ctx.fillStyle = 'black';
//       ctx.fillRect(0, 0, bounds.w, bounds.h);

//       //draw the images on the canvas
//       images.forEach((img) => {
//         if (!ctx) return;

//         ctx.drawImage(img as CanvasImageSource, 0, 0);
//       });

//       try {
//         // Use exportToBlob to generate the image Blob
//         const blob = await exportToBlob({
//           editor: editor,
//           format: 'png',
//           ids: selectedImages.map((s) => s.id),
//           opts: {
//             padding: 0,
//           },
//         });

//         if (!blob) {
//           console.error('Failed to generate image blob');
//           return [];
//         }

//         return new Promise((resolve, reject) => {
//           const reader = new FileReader();
//           reader.onloadend = async () => {
//             try {
//               const dataImg = reader.result?.toString() || '';
//               resolve([dataImg, canvas.toDataURL('image/png')]);
//             } catch (err) {
//               reject(err);
//             }
//           };
//           reader.readAsDataURL(blob);
//         });
//       } catch (error) {
//         console.error('Error exporting image:', error);
//         return [];
//       }
//     };

//     // Pass the callback to the parent component
//     setExportPreviewImageCallback(exportPreviewImage);
//     setExportForImagineCallback(exportForImagine);
//     setExportForInpaintCallback(exportForInpaint);
//   }, [editor, setExportPreviewImageCallback, setExportForImagineCallback, setExportForInpaintCallback]);

//   return null;
// };

const computeImagesBounds = (selectedImages: TLShape[]): Box => {
  // Compute bounding box
  const bounds = selectedImages.reduce(
    (acc, element) => {
      const image = element as TLImageShape;
      return {
        minX: Math.min(acc.minX, image.x),
        minY: Math.min(acc.minY, image.y),
        maxX: Math.max(acc.maxX, image.x + image.props.w),
        maxY: Math.max(acc.maxY, image.y + image.props.h),
      };
    },
    {
      minX: Infinity,
      minY: Infinity,
      maxX: -Infinity,
      maxY: -Infinity,
    }
  );

  return new Box(
    bounds.minX,
    bounds.minY,
    bounds.maxX - bounds.minX,
    bounds.maxY - bounds.minY
  );
};
